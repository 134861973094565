<template>
    <div class="l-stack">
        <!-- Loading state -->
        <div v-if="isLoading" class="l-stack l-center l-padded">
            <v-spinner size="medium" line-fg-color="#000" :speed="1" />
        </div>

        <div class="l-inline l-spread hidden-form_title l-padded">
            {{ $t('formTitle') }}
        </div>

        <div class="dropdown">
            <div class="l-stack l-gap-1 l-padded">
                <date-range-input
                    :value="customRange"
                    @input="loadConnectionHeatmap"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import moment from 'moment-timezone'
import VSpinner from 'vue-simple-spinner'

import { httpHelper } from '@/utils'

import DateRangeInput from '../DateRangeInput'

export default {
    name: 'ConnectionHeatmapView',
    components: {
        DateRangeInput,
        VSpinner,
    },
    props: {
        assetId: {
            type: [String, Number],
            default: null,
        },
        locationId: {
            type: [String, Number],
            default: null,
        },
    },
    data() {
        return {
            customRange: {
                startDate: moment()
                    .startOf('day')
                    .toDate(),
                endDate: moment()
                    .endOf('day')
                    .toDate(),
            },
            limit: 5000,
            isLoading: false,
            heatmapData: [],
        }
    },
    computed: {
        ...mapState('tracker', ['trackers']),
        assets() {
            if (this.assetId) {
                return this.trackers.filter(
                    tracker => tracker.id === parseInt(this.assetId)
                )
            } else if (this.locationId) {
                return this.trackers.filter(
                    tracker => tracker.location === parseInt(this.locationId)
                )
            } else {
                return []
            }
        },
    },
    methods: {
        ...mapMutations('map', ['addHeatmapData', 'resetHeatmapData']),
        async loadConnectionHeatmap({ startDate, endDate }) {
            this.isLoading = true
            this.resetHeatmapData()

            const startDateMoment = moment(startDate)
            const startDateFormatted = startDateMoment.format()
            const endDateMoment = moment(endDate)
            const endDateFormatted = endDateMoment.format()
            this.customRange = {
                startDate: startDateMoment.toDate(),
                endDate: endDateMoment.toDate(),
            }

            const url = [
                'measurements/?',
                'kind=gps-fix',
                '&timestamp_min=' + encodeURIComponent(startDateFormatted),
                '&timestamp_max=' + encodeURIComponent(endDateFormatted),
                '&limit=' + this.limit,
                ...this.assets.map(asset => '&tracker=' + asset.id),
            ].join('')

            await this.fetchHeatmapDataRecursively(url)
            this.addHeatmapData(this.heatmapData)
            this.isLoading = false
        },
        async fetchHeatmapDataRecursively(url) {
            const { data } = await httpHelper.get(url)

            this.heatmapData.push(...data.results)
            if (data.next) {
                await this.fetchHeatmapDataRecursively(data.next)
            }
        },
    },
    beforeRouteLeave(to, from, next) {
        this.resetHeatmapData()
        next()
    },
}
</script>

<i18n>
{
    "en": {
        "formTitle": "Date range"
    },
    "de": {
        "formTitle": "Zeitraum"
    },
    "it": {
        "formTitle": "Intervallo di date"
    }
}
</i18n>
